import { useState, useEffect } from "react"
import throttle from "lodash.throttle"

const useScrollPosition = () => {
  // Store the state
  const [scrollPos, setScrollPos] = useState()

  // Add and remove the window listener
  useEffect(() => {
    setScrollPos(window.scrollY)

    const handleScroll = throttle(() => {
      setScrollPos(window.pageYOffset)
    }, 50)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return scrollPos
}

export default useScrollPosition
