import React from "react"
import styled from "styled-components"
import { Container } from "../BaseElements/BaseElements"
import SocialList from "../SocialList/SocialList"
import { vars, media } from "../../variables"

import visa from "./images/visa.png"
import mastercard from "./images/mastercard.png"
import interac from "./images/interac-etransfer.png"

import map from "./images/icon-map.png"
import telephone from "./images/icon-telephone.png"
import envelope from "./images/icon-envelope.png"

const Footer = () => (
  <StyledFooter>
    <FooterContainer>
      <FooterInnerContainer>
        <ContactDetails>
          <ContactIcons>
            <ContactIcon>
              <ContactImage>
                <img src={map} alt="Map Icon" />
              </ContactImage>
              <ContactText>323 Richmond St E Toronto, ON</ContactText>
            </ContactIcon>
            <ContactIcon>
              <ContactImage>
                <img src={envelope} alt="Envelope Icon" />
              </ContactImage>
              <ContactText>info@treeandravine.com</ContactText>
            </ContactIcon>
            <ContactIcon>
              <ContactImage>
                <img src={telephone} alt="Telephone Icon" />
              </ContactImage>
              <ContactText>[416] 557- 9298</ContactText>
            </ContactIcon>
          </ContactIcons>
          <div>
            <PaymentMethods>
              <li>
                <img src={visa} alt="Visa logo" />
              </li>
              <li>
                <img src={interac} alt="Interac E-Transfer logo" />
              </li>
              <li>
                <img src={mastercard} alt="Mastercard logo" />
              </li>
            </PaymentMethods>
          </div>
        </ContactDetails>
        <SubFooter>
          <SocialList color="#939393" />
          <p>&copy;{new Date().getFullYear()} Tree and Ravine Inc.</p>
        </SubFooter>
      </FooterInnerContainer>
    </FooterContainer>
  </StyledFooter>
)

const StyledFooter = styled.footer`
  background: ${vars.brandcolor3};
  padding: 0;
  position: relative;
  z-index: 2;
`

const PaymentMethods = styled.ul`
  padding: 15px 0 0 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    padding-left: 8px;

    &:first-child {
      padding-left: 0;
    }

    img {
      max-height: 40px;
    }
  }
`

const FooterInnerContainer = styled.div`
  ${media.sm`
    display: flex;
  `}
`

const ContactDetails = styled.div`
  flex: 1 1 auto;
  text-align: center;
  color: #939393;
  padding-top: 25px;
`

const ContactIcons = styled.div`
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 500px) {
    display: flex;
  }

  > div {
    flex: 1 1 33%;
    margin-top: 15px;

    &:first-child {
      margin-top: 0px;
    }

    @media (min-width: 500px) {
      margin-top: 0;
    }
  }
`

const ContactIcon = styled.div`
  padding: 0 10px;
`

const ContactText = styled.div`
  display: block;
`
const ContactImage = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;

  img {
    display: block;
    align-self: center;
  }
`

const SubFooter = styled.div`
  background: ${vars.brandcolor4};
  padding: 15px;
  flex: 0 1 auto;
  color: #404040;
  text-align: center;

  ${media.sm`
    padding: 40px 15px;
  `}
`

const FooterContainer = styled(Container)`
  padding: 0;

  ${media.sm`
    padding: 0 15px;
  `}
`

export default Footer
