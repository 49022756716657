import React from "react"
import styled from "styled-components"
import { media } from "../../variables"
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"

const SocialList = ({ color }) => (
  <StyledSocialList color={color}>
    <li>
      <SocialLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/TreeandRavine"
        hoverColor="#3b5998"
        aria-label="Tree and Ravine Facebook Page"
      >
        <FaFacebookF />
      </SocialLink>
    </li>
    <li>
      <SocialLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/treeandravine"
        hoverColor="#55acee"
        aria-label="Tree and Ravine Twitter Page"
      >
        <FaTwitter />
      </SocialLink>
    </li>
    <li>
      <SocialLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/company/tree-and-ravine?trk=top_nav_home"
        hoverColor="#0976b4"
        aria-label="Tree and Ravine LinkedIn Page"
      >
        <FaLinkedin />
      </SocialLink>
    </li>
    <li>
      <SocialLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/treeandravine/"
        hoverColor="#a97b61"
        aria-label="Tree and Ravine Instagram Page"
      >
        <FaInstagram />
      </SocialLink>
    </li>
  </StyledSocialList>
)

const SocialLink = styled.a`
  display: block;
  font-size: 16px;
  padding: 10px;
  text-decoration: none;
  transition: color 0.3s ease-out;

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }

  ${media.sm`
    padding: 5px;
  `}
`

const StyledSocialList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;

  ${media.sm`
    margin-top: 0;
  `}

  li {
    display: inline-block;
    line-height: 18px;
    padding-left: 20px;

    &:first-child {
      padding-left: 0;
    }

    ${SocialLink} {
      color: ${({ color = "#404040" }) => color};
    }
  }
`

export default SocialList
