import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LogoImage from "./Images/logo.gif"
import { media } from "../../variables"

const Logo = ({ color }) => (
  <LogoContainer>
    <div>
      <StyledLogoImg to="/">
        <img src={LogoImage} alt="Tree and Ravine" />
      </StyledLogoImg>
    </div>
    <div>
      <StyledLogo color={color} to="/">
        Tree and Ravine
        <br />
        Every Tree. Healthy.
      </StyledLogo>
    </div>
  </LogoContainer>
)

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    flex: 0 0 auto;
    padding-left: 15px;

    &:first-child {
      padding-left: 0;
    }
  }
`

const StyledLogo = styled(Link)`
  color: ${({ color }) => color};
  font-weight: bold;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-align: center;
  transition: color 0.3s ease-out;

  ${media.sm`
    font-size: 16px;
  `}
`

const StyledLogoImg = styled(Link)`
  display: block;
  width: 50px;

  img {
    width: 100%;
  }
`

export default Logo
