import { media, vars } from "../../variables.js"
import styled from "styled-components"
import { Link as RouterLink } from "gatsby"

const Section = styled.div`
  padding: 50px 0 50px 0;

  ${media.sm`
    padding: 50px 0 50px 0;
  `}
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;

  ${media.sm`
    width: 768px;
  `}
  ${media.md`
    width: 992px;
  `}
  ${media.lg`
    width: 1200px;
  `}
`

const MedContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;

  ${media.sm`
    width: 500px;
  `}
  
  ${media.md`
    width: 768px;
  `}
  
  ${media.lg`
    width: 992px;
  `}
`

const SmallContainer = styled(Container)`
  max-width: 88.53vw;
  margin: 0 auto;
  padding: 0 6.66vw;

  ${media.sm`
    max-width: 50vw;
    padding: 0 3.64vw;
  `}
`

const H1 = styled.h1`
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding: 0;
  font-size: 25px;
  line-height: 32px;
  font-family: "Montserrat", "sans-serif";

  ${media.sm`
    font-size: 32px;
    line-height: 37px;
  `}

  ${media.md`
    font-size: 55px;
    line-height: 57px;
  `}
`

const H2 = styled.h2`
  padding-bottom: 10px;
  line-height: 40px;
  position: relative;
  display: inline-block;
  font-size: 45px;
  text-transform: uppercase;
  font-family: "Montserrat", "sans-serif";
  color: #404040;

  font-size: 32px;
  line-height: 37px;
  
  ${media.sm`
    line-height: 40px;
    font-size: 45px;
  `}
`

const H3 = styled.h3`
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0;
  line-height: 1.2em;
  font-size: 17px;
`

const P = styled.p`
  padding: 0;
  margin: 0 0 1em;
  line-height: 1.4em;
`

const Footnote = styled(P)`
  font-size: 1.89vw;
  ${media.sm`
    font-size: 0.73vw;
  `}
`

const Link = styled(RouterLink)`
  color: ${vars.brandcolor1};
  text-decoration: none;
`

export {
  Section,
  Container,
  MedContainer,
  SmallContainer,
  H1,
  H2,
  H3,
  P,
  Footnote,
  Link,
}
