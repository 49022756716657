import React from "react"
import styled from "styled-components"
// import { media } from "../../variables"
import Logo from "../Logo/Logo"
import { Container } from "../BaseElements/BaseElements"
// import Navigation from "./Navigation"
import useScrollPosition from "../../utils/scrollPosition"

const Header = ({ toggleHeader }) => {
  // const [open, setOpen] = useState(false)
  const scrollPosition = useScrollPosition()

  // const handleMenuClick = e => {
  //   setOpen(!open)
  // }

  return (
    <StyledHeader
      background={
        scrollPosition > 10 || !toggleHeader ? "white" : "transparent"
      }
    >
      <Container>
        <HeaderContainer>
          <Logo
            color={scrollPosition > 10 || !toggleHeader ? "black" : "white"}
          />
        </HeaderContainer>
      </Container>
    </StyledHeader>
  )
}

// const Hamburger = styled.div`
//   background: ${({ isOpen }) => (isOpen ? "#e96656" : "#808080")};
//   padding: 10px 10px;
//   cursor: pointer;
//   border-radius: 4px;
//   display: inline-block;
//   transition: background 0.3s ease-out;
//   z-index: 10;
//   position: relative;

//   ${media.sm`
//     display: none;
//   `}

//   div {
//     height: 2px;
//     width: 24px;
//     background: white;
//     transition: all 0.2s ease 0s;
//     transform: none;
//     margin-bottom: 5px;

//     &:nth-child(1) {
//       transform: ${({ isOpen }) =>
//         isOpen ? "translate(3px, -1px) rotate(45deg)" : "rotate(0deg)"};
//       transform-origin: left center;
//     }

//     &:nth-child(2) {
//       transform: ${({ isOpen }) =>
//         isOpen ? "translate(-10px, 0)" : "translate(0, 0)"};
//       opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
//     }

//     &:nth-child(3) {
//       transform-origin: left center;
//       transform: ${({ isOpen }) =>
//         isOpen ? "translate(3px, 1.5px) rotate(-45deg)" : "rotate(0deg)"};
//       margin-bottom: 0px;
//     }
//   }
// `

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 0 0 auto;
  }
`

// const HeaderRight = styled.div`
//   text-align: right;
// `

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  padding: 10px 0;
  z-index: 3;
  background: ${({ background }) => background};
  transition: all 0.3s ease-out;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.08);
`

export default Header
